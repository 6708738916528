const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = "__test__";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const writePersistentLocalStorage = (key: string, value: any): void => {
  if (isLocalStorageAvailable()) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  } else {
    const cookie = useCookie(key);
    try {
      cookie.value = JSON.stringify(value);
    } catch (e) {
      console.error(e);
    }
  }
};

export const getLocalStorage = (key: string): any | null => {
  if (isLocalStorageAvailable()) {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else {
    const cookie = useCookie(key);
    try {
      return cookie.value ? JSON.parse(cookie.value) : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};
